import React from "react";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { PlantProtectionEffectiveness } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultValues: PlantProtectionEffectiveness;
  isEditing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PlantProtectionEffectivenessPerParcel = ({
  defaultValues,
  isEditing,
  onChange,
}: Props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup
        className={classes.radioGroup}
        name="parcel-effectiveness"
        onChange={onChange}
        row
        value={defaultValues}
      >
        <FormControlLabel
          control={<Radio />}
          disabled={!isEditing}
          label={<FormattedMessage id="Por.Effectiveness.yes" />}
          labelPlacement="top"
          value={PlantProtectionEffectiveness.YES}
          classes={{
            root: classes.formControlLabel,
          }}
        />
        <FormControlLabel
          control={<Radio />}
          disabled={!isEditing}
          label={<FormattedMessage id="Por.Effectiveness.no" />}
          labelPlacement="top"
          value={PlantProtectionEffectiveness.NO}
          classes={{
            root: classes.formControlLabel,
          }}
        />
        <FormControlLabel
          control={<Radio />}
          disabled={!isEditing}
          label={<FormattedMessage id="Por.Effectiveness.not_specified" />}
          labelPlacement="top"
          value={PlantProtectionEffectiveness.NOT_SPECIFIED}
          classes={{
            root: classes.formControlLabel,
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PlantProtectionEffectivenessPerParcel;

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  formControlLabel: {
    flex: 1,
    textAlign: "center",
    margin: "0",
  },
}));
